<template>
    <section id="dashboard-ecommerce">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row class="match-height">
                <b-col xl="6" md="6">
                    <b-card-actions ref="cardFirstConnection" :noActions="true">
                        <b-row>
                            <b-col md="12" xl="5">
                                <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                    <v-select 
                                        disabled
                                        id="idEmpresa"                                        
                                        class="select-size-sm"
                                        v-model="filter.idEmpresa"
                                        :options="connections"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="12" xl="5">
                                <b-form-group label="Tipo" label-for="group" label-class="font-weight-bold">
                                    <v-select id="group"                                        
                                        class="select-size-sm"
                                        v-model="filter.tipo"
                                        :options="typeReport"
                                        :reduce="option => option.value" 
                                        label="label" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="2" class="mt-2">
                                <b-button size="sm" variant="primary" @click="getAllArticles">Buscar</b-button>
                            </b-col>
                        </b-row>
                    </b-card-actions>
                </b-col>

                <b-col xl="6" md="6">
                    <b-card-actions v-if="filter.idEmpresa && filter.grupoLineaId && lines.length > 0" ref="cardSecondConnection" :noActions="true">
                        <b-row>
                            <b-col md="12" xl="5">
                                <b-form-group label="Conexión" label-for="idEmpresa2" label-class="font-weight-bold">
                                    <v-select id="idEmpresa"                                        
                                        class="select-size-sm"
                                        v-model="filter2.idEmpresa"
                                        :options="connections.filter(item => item.idEmpresa !== filter.idEmpresa)"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="12" xl="5">
                                <b-form-group label="Grupo" label-for="group" label-class="font-weight-bold">
                                    <v-select id="group"                                        
                                        class="select-size-sm"
                                        v-model="filter2.grupoLineaId"
                                        :options="groupLines2"
                                        :reduce="option => option.grupo_Linea_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="2" class="mt-2">
                                <b-button size="sm" variant="primary" @click="getAllLinesArticles2">Buscar</b-button>
                            </b-col>
                        </b-row>
                    </b-card-actions>
                </b-col>
            </b-row>

            <b-card-actions ref="cardDetails" :noActions="true" :isTable="true">
                <div ref="tableContainer" class="mb-0_ table-responsive" :class="lines.length > 0 ? 'b-table-sticky-header' : ''">
                    <table ref="refListTable" class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                        <thead>
                            <tr>
                                <th>Articulo ID</th>
                                <th>Clabe</th>
                                <th>Articulo</th>
                                <th>Linea ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                </div>
            </b-card-actions>
        </b-overlay>
    </section>
</template>

<script>

import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from "vue-select";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as XLSX from 'xlsx';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

export default {
    name: 'GroupLinesArticles',
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,

        //
        BCardActions,
        vSelect,
        RecycleScroller,
        FeatherIcon,
    },
    data() {
        return {     
            loading: false,  
            typeReport: [
                { value: 'lineas', label: 'Lineas' },
                { value: 'grupos', label: 'Grupos' },                
            ], 
            lines: [],
            lines2: [],
            groupLines: [],
            groupLines2: [],
            filter: {
                idEmpresa: 1,
                tipo: null,
            },
            filter2: {
                idEmpresa: 2,
                tipo: null,
            }
        }
    },
    computed: {
        currentConnectionId() {
            return store.getters['auth/getConexionId']
        },
        connections() {
            return store.getters['auth/getConnections'].filter(c => c.idEmpresa === 1) //.filter(item => item.idEmpresa !== this.currentConnectionId)
        },
        currentConnection() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa === this.filter.idEmpresa)
        },
        secondaryConnections() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa == this.filter2.idEmpresa)
        },
    },
    async mounted() {
        //await this.getAllLinesArticles()
    },
    methods: {
        async selectConnection(evt) {
            await this.getAllGroupLinesArticles()
        },
        async selectConnection2(evt) {
            await this.getAllGroupLinesArticles2()
        },
        async getAllGroupLinesArticles() {
            this.groupLines = []
            try {
                const query = {
                    idEmpresa: this.filter.idEmpresa,
                }
                this.loading = true
                const { data } = await ArticlesResource.getAllGroupLinesArticles(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.groupLines = data.data
                }                
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getAllGroupLinesArticles2() {
            this.groupLines2 = []
            try {
                const query = {
                    idEmpresa: this.filter2.idEmpresa,
                }
                this.loading = true
                const { data } = await ArticlesResource.getAllGroupLinesArticles(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.groupLines2 = data.data
                }                
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getAllArticles() {
            this.lines = []
            try {

                const query = {
                    ...this.filter,
                }
                this.loading = true
                const { data } = await ArticlesResource.ObtenerArticulosParaRelacionesGruposLineas(query)
                this.loading = false
                console.log(data)

                if (data.isSuccesful) {
                    
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getAllLinesArticles2() {
            this.lines2 = []
            try {

                const query = {
                    idEmpresa: this.filter2.idEmpresa,
                    grupoLineaId: 0,
                    search: null,
                }
                this.loading = true
                const { data } = await ArticlesResource.getAllLinesArticles(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.lines2 = data.data
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        }
    }
}
</script>